import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Experience from 'components/Experience';
import Education from 'components/Education';
import Skills from 'components/Skills';

const ProfilePage: React.FC = () => (
  <Layout>
    <SEO title="11+ years experienced RoR Developer in Pune" description='Are you looking well experienced RoR profile in Pune who can lead the team and handle multiple projects, also clients with ease? Visit this profile' />
      <Experience />
      <hr />
      <Education />
      <hr />
      <Skills />
  </Layout>
);

export default ProfilePage;
